import React, { useReducer } from 'react'

import type { FC } from 'react'

import { BreadcrumbsContext } from './BreadcrumbsContext'
import { breadcrumbsReducer } from './BreadcrumbsReducer'
import type { Breadcrumb } from './types'

export interface BreadcrumbsState {
	breadcrumbs: Breadcrumb[]
}

const BREADCRUMBS_INITIAL_STATE: BreadcrumbsState = {
	breadcrumbs: null,
}

export const BreadcrumbsProvider: FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [state, dispatch] = useReducer(
		breadcrumbsReducer,
		BREADCRUMBS_INITIAL_STATE
	)

	const setBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
		dispatch({
			type: '[Breadcrumbs] - Set breadcrumbs',
			payload: breadcrumbs,
		})
	}

	return (
		<BreadcrumbsContext.Provider
			value={{
				...state,
				setBreadcrumbs,
			}}
		>
			{children}
		</BreadcrumbsContext.Provider>
	)
}
